import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 7,
  pages: 7,
  zoom: 1000,
  images: ["img/laura.jpg"],
  // diamonds: [
  //   { x: 0, offset: 0.1, pos: new Vector3(), factor: 1.25 },
  //   { x: 0, offset: 1, pos: new Vector3(), factor: 1.5 },
  //   { x: 0, offset: 2, pos: new Vector3(), factor: 0.75 }
  // ],
  top: createRef()
}

export default state
